<script>
import Loading from "@components/Loading";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "Living",
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    document.querySelector('.lefttabbox').style.display = "none";
    document.querySelector('.fixedBox').style.display = "none";
    const { id } = this.$route.query;
    try {
      await this.$store.dispatch("LiveDetailModel/getLiveUrl", { id });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
        await this.$store.dispatch("LiveDetailModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
  },
  destroyed(){
    document.querySelector('.lefttabbox').style.display = "block";
    document.querySelector('.fixedBox').style.display = "block";
  },
  render() {
    const { liveUrl, isLoadingShow } = this.$store.state.LiveDetailModel;
	console.log(liveUrl);
    return (
      <div>
        <iframe
          class="iframe"
          src={liveUrl}
          frameborder="0"
          allowfullscreen="true"
          allowtransparency="true"
          width="100%"
          height="100%"
        />
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.iframe {
  height: 750px;
}
</style>